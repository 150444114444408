.universe-screen {
  text-align: center;

  // Labels rendered by CSS3DSprite
  .node-label {
    font-size: 4px;
    padding: 0 2px;
    line-height: 8px;
    border-radius: 2px;
    background-color: rgba(0,0,0,0.5);
    user-select: none;
    margin-top: 8px;
  }

  .node-label--hidden {
    display: none;
  }
}

.lune-modal {
  position: relative;
}

.lune-container {
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;

  p {
    font-style: italic;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }
}

.close-lune {
  display: block;
  margin: 0 auto 0;
}
