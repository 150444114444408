@import "reset";
@import "typography";
@import "utilities";

body {
  margin: 0;
  background-color: #111111;
}

#root {
  min-height: 100vh;
}

a, button {
  outline: none;
}

// Form

input {
  display: block;
  margin: 0;
  width: 100%;

  padding: 16px 18px;
  border-radius: 8px;
  background-color: #FFF;
  border: none;
  outline: none;

  font-family: $font-family;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

textarea {
  padding: 12px 12px;
  min-height: 200px;
  border-radius: 8px;
  background-color: #FFF;
  border: none;
  outline: none;
  width: 100%;

  font-family: $font-family;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

button {
  padding: 6px 24px;
  border: 1px solid #FFF;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 900;
  line-height: 21px;
  background: transparent;
  color: #FFF;
  letter-spacing: 1px;
}


.round-button {
  display: block;
  box-sizing: border-box;
  height: 96px;
  width: 96px;
  border: 2px solid #808080;
  border-radius: 100px;
  cursor: pointer;
  margin: 0 auto;
  font-family: $font-family;
  padding: 0;
  letter-spacing: normal;

  &:hover {
    background-color: #fff;
    color: #000;
  }

  &:disabled,
  &[disabled],
  &:disabled:hover {
    color: #4a4a4a;
    border-color: #4a4a4a;
    background-color: transparent;
    opacity: 0%;
  }
}

.starfield-container {
  position: relative;

  .starfield {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;

    padding: 0;
    margin: 0;
  }
  .starfield-content {
    position: relative;
  }
}
