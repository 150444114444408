@import "../styles/utilities";
@import "../styles/index";

.section {
  height: 100vh;
  @include flexCenter(column, center, center);
}

.section-logo {
}

.section-about {
  padding: 0 10%;

  .about-wrapper {
    max-width: 700px;
    text-align: center;
  }

  h3 {
    margin-bottom: 32px;
  }
}

.section-next {
}

.logo {
  // width: 467px;
  // height: 612px;
  width: 234px;
  height: 306px;
  max-width: 45%;

  background: url('../styles/images/Logo-large.png') no-repeat 50% 50% transparent;
  background-size: contain;
}
$scroll-down-width: 64px;
.scroll-down {
  position: fixed;
  bottom: 70px;
  width: $scroll-down-width;
  height: 200px;
  left: 50%;
  margin-left: $scroll-down-width * -0.5;
  padding: 0 ($scroll-down-width - 6px) * 0.5;
  cursor: pointer;

  > div {
    // background: url('../styles/images/scroll-down.png') no-repeat 50% 50% transparent;
    animation-name: scroll-line;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    height: 100px;
    width: 6px;
    border-radius: 50px;
    background: #fff;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);

    background-size: contain;
  }
}

@keyframes scroll-line {
  from {
    transform: scale(1,0) translateY(0px);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  to {
    transform: scale(1,1.2) translateY(80px);
    opacity: 0;
  }
}

.write-button, .read-button {
  @extend .round-button;
  flex: auto;
  margin: 0 16px;
  height: 136px;
  width: 136px;

  font-size: 18px;
  line-height: 24px;

  &:hover {
    background-color: #fff;
    color: #000;
  }
}
