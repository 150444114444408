
.mt24 {
  margin-top: 24px;
}

.mt32 {
  margin-top: 32px;
}

.mb4 {
  margin-bottom: 4px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb32 {
  margin-bottom: 32px;
}

.mb48 {
  margin-bottom: 48px;
}

.mb64 {
  margin-bottom: 64px;
}

@mixin flexCenter($direction, $align, $justify) {
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
  display: flex;
}
