@import "../styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,400;0,600;1,400&display=swap');

$font-family-nunito: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
//$font-family: 'Karla', sans-serif;
$font-family: 'Karla', 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

body {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  color: #FFF;
}

h1 {
  font-family: $font-family;
  font-size: 36px;
  line-height: 48px;
  font-weight: 200;
  margin-bottom: 32px;
}

h2 {
  font-size: 40px;
  line-height: 56px;
  font-weight: 200;
  margin-bottom: 32px;
}

h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;

  @media (min-width: $breakpoint-md) {
    font-size: 32px;
    line-height: 38px;
  }
}

h4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 900;
}
