@import "../styles/utilities";
@import "../styles/variables";

.wrapper {
  @include flexCenter(column, center, center);
  width: 100vw;
  height: 100vh;
  .wrapperBlock {
    width: 100%;
    padding: 24px;
    @media (min-width: $breakpoint-md) {
      max-width: 600px;
    } 
    @media (min-width: $breakpoint-lg) {
      max-width: 700px;
    } 
  }
} 