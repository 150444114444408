@import "../styles/typography";
@import "../styles/index";
@import "../styles/variables";

.question-screen {
  text-align: left;

  .question {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 25px;

    @media (min-width: $breakpoint-md) {
      margin-bottom: 16px;
      font-size: 36px;
      line-height: 25px;
    }
  }

  .hint {
    height: 30px;
    //width: 292px;
    color: #FFF;
    font-family: $font-family;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    margin: 12px 0 12px;

    animation-name: scroll-hint;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    overflow: hidden;
    opacity: 100%;

    @media (min-width: $breakpoint-md) {
      margin: 28px 0 28px;
      font-size: 28px;
      line-height: 25px;
    }
  }

  @keyframes scroll-hint {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    20% {
      transform: translateY(0px);
      opacity: 1;
    }
    80% {
      transform: translateY(0px);
      opacity: 1;
    }
    to {
      transform: translateY(-20px);
      opacity: 0;
    }
  }

  .next-button {
    @extend .round-button;
    font-size: 18px;
    line-height: 21px;
  }
}

.answer-input {
  position: relative;

  &__characters-left {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 16px;
    font-family: $font-family;
    font-size: 16px;
    line-height: 24px;
    color: #333;

    @media (min-width: $breakpoint-md) {
      font-size: 18px;
    }
  }
}
