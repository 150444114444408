@import "../styles/variables";


.textBlock {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding: 130px 20px 20px;

  @media (min-width: $breakpoint-md) {
    position: relative;
    padding: 0;
  }  

  h4 {
    font-size: 22px;
    line-height: 50px;
  }

  p {
    font-size: 18px;
    margin-bottom: 32px;
  }
}
