@import "../styles/variables";

.nav--mobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  @media (min-width: $breakpoint-md) {
    display: none;
  }
}

.logo {
  position: absolute;
  width: 54px;
  height: 70px;
  top: 32px;
  left: 50%;
  margin-left: -54px * 0.5;

  background: url("../styles/images/Logo.png") no-repeat 50% 50% transparent;
  background-size: contain;
  cursor: pointer;
}

.logo-spacer {
  width: 64px;
}

.menu {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 40px;
  left: 32px;
  right: 32px;

  text-align: center;
}

.menu__link {
  display: block;
  padding: 8px 0;
  color: #BBB;
  text-decoration: none;
  border-bottom: 2px solid transparent;

  font-size: 14px;
  line-height: 20px;
  min-width: 40px;

  &:hover {
    color: #fff;
    border-bottom: 2px solid #fff;
  }

  &--active {
    color: #FFF;
  }
}

.credits-footer {
  position: absolute;
  width: 246px;
  height: 95px;
  bottom: 32px;
  left: 64px;

  background: url("../styles/images/credits-footer.png") no-repeat 50% 50% transparent;
  background-size: contain;
}
