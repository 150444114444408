@import "../styles/variables";

.nav {
  @media (max-width: $breakpoint-md) {
    display: none;
  }
}

.logo {
  position: absolute;
  width: 72px;
  height: 96px;
  top: 64px;
  left: 64px;

  background: url("../styles/images/Logo.png") no-repeat 50% 50% transparent;
  background-size: contain;
  cursor: pointer;
}

.menu {
  display: block;
  position: absolute;
  width: 50px;
  top: 64px + 96px + 64px;
  bottom: 95px + 32px;
  left: 64px;

  text-align: left;
}

.menu__link {
  display: block;
  padding: 8px 0;
  color: #BBB;
  text-decoration: none;
  border-bottom: 2px solid transparent;

  &:hover {
    color: #fff;
    border-bottom: 2px solid #fff;
  }

  &--active {
    color: #FFF;
  }
}

.credits-footer {
  position: absolute;
  width: 246px;
  height: 95px;
  bottom: 32px;
  right: 50px;

  background: url("../styles/images/credits-footer.png") no-repeat 50% 50% transparent;
  background-size: contain;
}
