@import "../styles/index";
@import "../styles/variables";

.keepintouch-screen {

  .question {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 25px;

    @media (min-width: $breakpoint-md) {
      margin-bottom: 48px;
      font-size: 36px;
      line-height: 25px;
    }
  }

  .next-button {
    @extend .round-button;
    font-size: 14px;
    line-height: 16px;
  }
}
