.textBlock {
  max-width: 700px;
  text-align: center;

  h3 {
    // font-size: 24px;
    // line-height: 32px;
    // margin: auto 30px 32px;
    margin-bottom: 32px;
  }
}
